import React, { useRef, useEffect } from "react";
import { useClickAway } from "react-use";
import styled from "styled-components";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  background: #fff;
  overflow-y: scroll;

  z-index: 200;

  width: 500px;

  @media (max-width: 600px) {
    display: none;
  }

  & .popup-inner-container {
    padding: 100px 30px 30px 30px;
  }
`;

export const ProjectText = ({ text, setIsProjectTextOpen }) => {
  const ref = useRef(null);
  // useClickAway(ref, () => {
  //   setIsProjectTextOpen(false);

  //   // Enable body scroll
  //   enableBodyScroll(targetElement);
  //   clearAllBodyScrollLocks();
  // });

  let targetElement = null;
  useEffect(() => {
    // Disable body scroll
    if (ref !== null) {
      targetElement = ref.current;
      disableBodyScroll(ref.current);
    }
  }, [ref]);

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  return (
    <Popup ref={ref}>
      <div className="popup-inner-container">
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </div>
    </Popup>
  );
};
