import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "../styles/gallery.css";

// Utils
import { ImageOrientation } from "../utils/image-orientation";

// Components

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;

  & .carousel.carousel-slider,
  & .slider-wrapper,
  & .image-gallery,
  & .slider,
  & .slide,
  & video,
  & img {
    height: 100%;
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 50%;
  }
`;

const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;

    object-position: center;

    &.landscape {
      object-fit: cover;
    }

    &.portrait {
      object-fit: contain;
    }
  }
`;

async function getDominantColor(url) {
  let response = await fetch(`${url}&palette=json`);
  let data = await response.json();
  return data;
}

export const Gallery = ({ images, setNavigationTextColor }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [allGalleryContent, setAllGalleryContent] = useState(null);
  const [totalSlides, setTotalSlides] = useState(null);

  useEffect(() => {
    setTotalSlides(images.length);
    setAllGalleryContent(images);
  }, []);

  const updateCurrentSlide = index => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  useEffect(() => {
    if (allGalleryContent !== null) {
      const dominantColor = getDominantColor(
        allGalleryContent[currentSlide].primary.image.url
      );

      dominantColor.then(result => {
        const textColor = result.average_luminance <= 0.5 ? "#fff" : "#000";

        setNavigationTextColor(textColor);
      });
    }
  }, [currentSlide]);

  const galleryContent = images.map((content, index) => {
    if (content.slice_type === "image") {
      return (
        <ImageContainer key={`homepage_images_${index}`}>
          {content.primary.image.fluid !== null && (
            <img
              className={ImageOrientation(content.primary.image)}
              srcSet={content.primary.image.fluid.srcSetWebp}
              src={content.primary.image.fluid.srcWebp}
              alt={content.primary.image.alt}
            />
          )}
        </ImageContainer>
      );
    }
  });

  return (
    <GalleryContainer>
      <Carousel
        showThumbs={false}
        autoPlay={true}
        showIndicators={false}
        showStatus={false}
        infiniteLoop={true}
        useKeyboardArrows={true}
        className="image-gallery"
        selectedItem={currentSlide}
        onChange={index => updateCurrentSlide(index)}
        stopOnHover={false}
        interval={7000}
        transitionTime={750}
      >
        {galleryContent}
      </Carousel>
    </GalleryContainer>
  );
};
