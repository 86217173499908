import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { withPreview } from "gatsby-source-prismic";
import { Helmet } from "react-helmet";
import Div100vh from "react-div-100vh";

// Components
import { Gallery } from "../components/images/gallery";
import { ProjectText } from "../components/project/project-text";

const Page = styled.div`
  height: 100%;
  overflow: hidden;
`;

const Grid = styled.ol``;

const Nav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 300;

  & ol {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;

    padding: 30px;
    margin: 0;

    & li {
      & a,
      & button {
        cursor: pointer;

        color: ${props => props.color};
      }
    }

    @media (max-width: 768px) {
      padding: 20px;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px px;

      & .spacer {
        display: none;
      }
    }
  }
`;

const Project = ({ data }) => {
  const [isProjectTextOpen, setIsProjectTextOpen] = useState(false);
  const [navigationTextColor, setNavigationTextColor] = useState(`#000`);

  return (
    <>
      <Helmet
        title={`Arc Editions - ${data.prismicProject.data.artist.document.data.name.text} - ${data.prismicProject.data.title.text}`}
      />
      <Div100vh>
        <Page>
          <Nav color={navigationTextColor}>
            <ol>
              <li>
                <button
                  onClick={() => {
                    if (isProjectTextOpen) {
                      setIsProjectTextOpen(false);
                    } else {
                      setIsProjectTextOpen(true);
                    }
                  }}
                >
                  About
                </button>
              </li>
              <li className="spacer"></li>
              <li>
                <Link to={`/${data.prismicProject.data.artist.document.uid}`}>
                  Close
                </Link>
              </li>
            </ol>
          </Nav>

          {isProjectTextOpen && (
            <ProjectText
              text={data.prismicProject.data.text.html}
              setIsProjectTextOpen={setIsProjectTextOpen}
            />
          )}

          <Gallery
            images={data.prismicProject.data.body}
            setNavigationTextColor={setNavigationTextColor}
          />
        </Page>
      </Div100vh>
    </>
  );
};

export default withPreview(Project);

export const query = graphql`
  query Project($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      data {
        title {
          html
          text
        }
        text {
          html
        }
        artist {
          document {
            ... on PrismicArtist {
              id
              uid
              data {
                name {
                  text
                }
              }
            }
          }
        }
        body {
          ... on PrismicProjectBodyImage {
            id
            slice_type
            primary {
              image {
                alt
                url
                fluid {
                  srcWebp
                  srcSetWebp
                }
                dimensions {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;
